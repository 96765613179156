import React from 'react';

function CheckMarkServiceDesign() {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17.4057"
        cy="16.7079"
        r="11.6554"
        transform="rotate(-180 17.4057 16.7079)"
        fill="#FFED0A"
      />
      <path
        d="M6.36514 16.5604C6.36514 13.4454 7.46683 10.962 9.37711 9.10738C11.312 7.22884 14.1683 5.9107 17.7814 5.35484C21.073 4.84843 23.7552 6.04688 25.6625 8.15284C27.6022 10.2947 28.7414 13.3924 28.7414 16.5604C28.7414 22.7394 23.7323 27.7485 17.5533 27.7485C13.2234 27.7485 10.5098 26.4375 8.85226 24.5442C7.16302 22.6147 6.36514 19.8472 6.36514 16.5604Z"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M20.528 5.24655C23.6534 6.09302 25.8484 7.87451 27.1917 10.2979C28.5516 12.7514 29.0962 15.9734 28.673 19.7416C28.249 23.5175 26.9139 26.2745 24.7985 27.7169C22.7256 29.1303 19.4837 29.5609 14.5735 27.7456C9.7619 25.9668 7.17714 23.1683 5.9505 20.1268C4.70385 17.0357 4.7885 13.5111 5.678 10.2268C6.25842 8.08373 7.07354 6.68032 7.98149 5.7614C8.87733 4.85474 9.94369 4.33795 11.1816 4.0951C13.7696 3.58738 16.9802 4.2857 20.528 5.24655Z"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M13.7298 15.8359L17.2298 19.3359C19.9168 14.6036 21.3889 11.9678 27.7298 5.33594"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckMarkServiceDesign;
