import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignCalendarStyles } from './styles';
import calendar from '../../../assets/images/serviceDesign/calendar.png';
import calendar1 from '../../../assets/images/serviceDesign/calendar1.png';
import calendar2 from '../../../assets/images/serviceDesign/calendar2.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import LongArrow from '../../../assets/icons/LongArrow';

export const ServiceDesignCalendar = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignCalendarStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_42')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_43')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-calendar">
            <img src={calendar} alt="" />
          </div>
          <div className="cards-item">
            <img src={calendar1} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_45')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_46')}</p>
              <a href="https://conference.4service.group/basic-sd/">
                <LongArrow />
                <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_49')}</span>
              </a>
            </div>
          </div>
          <div className="cards-item">
            <img src={calendar2} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_47')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_48')}</p>
              <a href="https://conference.4service.group/practical-workshop-with-employee-experience/">
                <LongArrow />
                <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_49')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="btns">
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#000"
            textColor="#000"
            borderColor="#000"
            borderOnHover="#000"
            background="#FFED0A"
            hoverBackground="#FFED0A"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'usage_attitude_11',
            )}
          />
        </div>
      </div>
    </ServiceDesignCalendarStyles>
  );
};
