import styled from 'styled-components';

export const ServiceDesignAboutStyles = styled.div`
  .container {
    margin-top: 100px !important;

    @media (max-width: 991px) {
      margin-top: 50px !important;
    }
  }

  .content {
    gap: 25px !important;
  }

  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  img {
    position: absolute;
    top: 60%;
    transform: translate(0, -50%);
    left: 25px;

    @media (max-width: 991px) {
      position: static;
      transform: none;
      max-width: 300px;
      width: 100%;
      align-self: center;
    }
  }
`;
