import styled from 'styled-components';

export const ServiceDesign4serviceStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .btns {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    gap: 25px;
    margin-top: 25px;

    img {
      max-width: 370px;
      width: 100%;
      flex-shrink: 0;
      border-radius: 5px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      max-width: 770px;
      gap: 25px;

      h3 {
        font-weight: 400;
        font-size: 28px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;
      }
    }

    &-lists {
      display: flex;
      align-items: center;
      gap: 35px;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &:nth-child(1) {
        flex: 1 1 66%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        flex: 1 1 33%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;

        span {
          font-weight: 700;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 25px;
        list-style-type: disc;

        li {
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          color: #0f0e0c;

          b {
            font-weight: 700;
          }
        }
      }
    }

    &-locations {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-location {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;
      }
    }
  }
`;
