import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignApproachStyles } from './styles';
import approach from '../../../assets/images/serviceDesign/approach.png';

export const ServiceDesignApproach = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignApproachStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'solutions')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_50')}
          </h2>
          <div className="approach">
            <div className="approach-text">
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_51')}</p>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_52')}</p>
            </div>
            <img src={approach} alt="" />
          </div>
        </div>
      </div>
    </ServiceDesignApproachStyles>
  );
};
