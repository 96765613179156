import styled from 'styled-components';

export const ServiceDesignServicesStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .btns {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }

  .cards {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 25px;

    @media (max-width: 768px) {
      gap: 15px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: calc(25% - 20px);

      @media (max-width: 1200px) {
        width: calc(50% - 15px);
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      img {
        max-width: 100px;
      }

      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;
