import styled from 'styled-components';

export const ServiceDesignApproachStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .approach {
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    img {
      flex-shrink: 0;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      p {
        font-weight: 400;
        font-size: 28px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
