import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignServicesStyles } from './styles';
import ourServices1 from '../../../assets/images/serviceDesign/our-services1.png';
import ourServices2 from '../../../assets/images/serviceDesign/our-services2.png';
import ourServices3 from '../../../assets/images/serviceDesign/our-services3.png';
import ourServices4 from '../../../assets/images/serviceDesign/our-services4.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const ServiceDesignServices = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignServicesStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'solutions')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_28')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={ourServices1} alt="" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_29')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_30')}</p>
          </div>
          <div className="cards-item">
            <img src={ourServices2} alt="" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_31')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_32')}</p>
          </div>
          <div className="cards-item">
            <img src={ourServices3} alt="" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_33')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_34')}</p>
          </div>
          <div className="cards-item">
            <img src={ourServices4} alt="" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_35')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_36')}</p>
          </div>
        </div>
      </div>
      <div className="btns">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="#FFED0A"
          hoverBackground="#FFED0A"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'usage_attitude_11',
          )}
        />
      </div>
    </ServiceDesignServicesStyles>
  );
};
