import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignAboutStyles } from './styles';
import about from '../../../assets/images/serviceDesign/about.png';

export const ServiceDesignAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'brand_awareness_5')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_9')}
          </h2>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_10')}</p>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_11')}</p>
        </div>
        <img src={about} alt="" />
      </div>
    </ServiceDesignAboutStyles>
  );
};
