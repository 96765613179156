import styled from 'styled-components';

export const ServiceDesignWorkshopsStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 125%;
      color: #0f0e0c;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .cards {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 100%;
        max-width: 340px;
      }

      p {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        max-width: 240px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
        color: #0f0e0c;
      }
    }
  }
`;
