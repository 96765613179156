import React, { useEffect } from 'react';
import { ServiceDesignStyles } from './styles';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import { useAppSelector } from '../../state/hooks';
import {
  ServiceDesign4service,
  ServiceDesignAbout,
  ServiceDesignApproach,
  ServiceDesignCalendar,
  ServiceDesignHead,
  ServiceDesignIntro,
  ServiceDesignPartners,
  ServiceDesignPresentations,
  ServiceDesignServices,
  ServiceDesignSolution,
  ServiceDesignTools,
  ServiceDesignVideoslider,
  ServiceDesignWhy,
  ServiceDesignWorkshops,
} from '../../components';

export const ServiceDesign = () => {
  useEffect(() => {}, []);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ServiceDesignStyles>
      <ServiceDesignIntro />
      <ServiceDesignAbout />
      <ServiceDesignSolution />
      <ServiceDesignWhy />
      <ServiceDesignServices />
      <ServiceDesignVideoslider />
      <ServiceDesignWorkshops />
      <ServiceDesignCalendar />
      <ServiceDesignApproach />
      <ServiceDesign4service />
      <ServiceDesignTools />
      <ServiceDesignPresentations />
      <ServiceDesignHead />
      <ServiceDesignPartners />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </ServiceDesignStyles>
  );
};
