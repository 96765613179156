import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ServiceDesignPartnersStyles } from './styles';
import { partnersTheme } from './utils';
import Marquee from 'react-fast-marquee';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const ServiceDesignPartners = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [data, setData] = useState<{ static: string; hover: string; alt: string }[]>();

  useEffect(() => {
    setData([
      ...partnersTheme,
    ]);
  }, []);

  return (
    <ServiceDesignPartnersStyles>
      <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_1')}</h1>
      <div className="partner-quee">
        {data && (
          <Marquee speed={window.innerWidth > 1000 ? 100 : 120}>
            {data.map((item, index) => (
              <div className="logoWrapper" key={item.alt}>
                <img src={item.static} alt={item.alt} />
                <img src={item.hover} alt={item.alt} />
              </div>
            ))}
          </Marquee>
        )}
      </div>
    </ServiceDesignPartnersStyles>
  );
};
