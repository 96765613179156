import styled from 'styled-components';

export const ServiceDesignToolsStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .cards {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;

    &-item {
      display: flex;
      flex-direction: column;
      max-width: 370px;
      width: 100%;
      gap: 8px;

      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 7px;

      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
