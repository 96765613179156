import styled from 'styled-components';

export const ServiceDesignSolutions = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    gap: 25px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: calc(50% - 25px);

      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        max-width: 400px;
      }

      img {
        max-width: 110px;
      }

      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        color: #000000;
        margin: 0;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 18px;
        }
      }

      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 125%;
        color: #000000;
        margin: 0;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }
`;
