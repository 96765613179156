import slide1 from '../../../assets/images/serviceDesign/slide-1.png';
import slide2 from '../../../assets/images/serviceDesign/slide-2.png';
import slide3 from '../../../assets/images/serviceDesign/slide-3.png';
import slide4 from '../../../assets/images/serviceDesign/slide-4.png';
import slide5 from '../../../assets/images/serviceDesign/slide-5.png';
import slide6 from '../../../assets/images/serviceDesign/slide-6.png';
import slide7 from '../../../assets/images/serviceDesign/slide-7.png';
import slide8 from '../../../assets/images/serviceDesign/slide-8.png';
import slide9 from '../../../assets/images/serviceDesign/slide-9.png';
import slide10 from '../../../assets/images/serviceDesign/slide-10.png';
import slide11 from '../../../assets/images/serviceDesign/slide-11.png';
import slide12 from '../../../assets/images/serviceDesign/slide-12.png';
import slide13 from '../../../assets/images/serviceDesign/slide-13.png';
import slide14 from '../../../assets/images/serviceDesign/slide-14.png';
import slide15 from '../../../assets/images/serviceDesign/slide-15.png';
import slide16 from '../../../assets/images/serviceDesign/slide-16.png';
import slide17 from '../../../assets/images/serviceDesign/slide-17.png';
import slide18 from '../../../assets/images/serviceDesign/slide-18.png';
import slide19 from '../../../assets/images/serviceDesign/slide-19.png';
import slide20 from '../../../assets/images/serviceDesign/slide-20.png';
import slide21 from '../../../assets/images/serviceDesign/slide-21.png';
import slide22 from '../../../assets/images/serviceDesign/slide-22.png';
import slide23 from '../../../assets/images/serviceDesign/slide-23.png';
import slide24 from '../../../assets/images/serviceDesign/slide-24.png';
import slide25 from '../../../assets/images/serviceDesign/slide-25.png';
import slide26 from '../../../assets/images/serviceDesign/slide-26.png';
import slide27 from '../../../assets/images/serviceDesign/slide-27.png';
import slide28 from '../../../assets/images/serviceDesign/slide-28.png';
import slide29 from '../../../assets/images/serviceDesign/slide-29.png';
import slide30 from '../../../assets/images/serviceDesign/slide-30.png';
import slide31 from '../../../assets/images/serviceDesign/slide-31.png';
import slide32 from '../../../assets/images/serviceDesign/slide-32.png';
import slide33 from '../../../assets/images/serviceDesign/slide-33.png';
import slide34 from '../../../assets/images/serviceDesign/slide-34.png';

export const partners: { label: string, slides: string[] }[] = [
  {
    label: 'HYUNDAI',
    slides: [slide1, slide2],
  },
  {
    label: 'Gorodok',
    slides: [slide3, slide4, slide5],
  },
  {
    label: 'INDITEX',
    slides: [slide6, slide7],
  },
  {
    label: 'Ministry',
    slides: [slide8, slide9, slide10, slide11],
  },
  {
    label: 'Justin',
    slides: [slide12, slide13, slide14],
  },
  {
    label: 'Oppo',
    slides: [slide15, slide16],
  },
  {
    label: 'Pharma',
    slides: [slide17, slide18],
  },
  {
    label: 'OYSHO',
    slides: [slide19, slide20],
  },
  {
    label: 'Pumb',
    slides: [slide21, slide22],
  },
  {
    label: 'Yasno',
    slides: [slide23, slide24],
  },
  {
    label: 'DTEK',
    slides: [slide25, slide26, slide27, slide28],
  },
  {
    label: 'Oshadbank',
    slides: [slide29, slide30],
  },
  {
    label: 'Tvoya shina',
    slides: [slide31, slide32],
  },
  {
    label: 'M-lombard',
    slides: [slide33, slide34],
  },
];
