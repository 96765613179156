import React from 'react';

function LocationMark() {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2426 2.44365C10.9844 -0.81455 5.70185 -0.81455 2.44365 2.44365C-0.814551 5.70185 -0.814549 10.9844 2.44365 14.2426L8.34314 20.1421L14.2426 14.2426C17.5008 10.9844 17.5008 5.70185 14.2426 2.44365ZM8.487 11.5079C10.3142 11.5079 11.7955 10.0267 11.7955 8.19946C11.7955 6.37223 10.3142 4.89097 8.487 4.89097C6.65977 4.89097 5.17851 6.37223 5.17851 8.19946C5.17851 10.0267 6.65977 11.5079 8.487 11.5079Z" fill="#FFED0A" />
    </svg>
  );
}

export default LocationMark;
