import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const ServiceDesignHeadStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;
  background: #FFED0A;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;

    @media (max-width: 768px) {
      align-items: center !important;
    }
  }

  .section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 50px;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  img {
    @media (max-width: 991px) {
      max-width: 250px;
    }

    @media (max-width: 768px) {
      max-width: 200px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    color: #000000;

    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    color: #0F0E0C;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 18px;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 125%;
    color: #0F0E0C;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 16px;
    }
  }
`;
