import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesign4serviceStyles } from './styles';
import service from '../../../assets/images/serviceDesign/4service.png';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import LocationMark from '../../../assets/icons/LocationMark';

export const ServiceDesign4service = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesign4serviceStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_67')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_68')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="info">
          <img src={service} alt="" />
          <div className="info-text">
            <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_69')}</h3>
            <div className="info-lists">
              <div className="info-list">
                <h4>
                  <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_70')}</span>
                </h4>
                <ul>
                  <li dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'service_design_71',
                    ),
                  }}
                  />
                  <li dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'service_design_72',
                    ),
                  }}
                  />
                  <li dangerouslySetInnerHTML={{
                    __html: getTranslationsByLangOrEng(
                      interfaceLanguage,
                      'service_design_73',
                    ),
                  }}
                  />
                </ul>
              </div>
              <div className="info-list">
                <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_74')}</h4>
                <div className="info-locations">
                  <div className="info-location">
                    <LocationMark />
                    <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_75')}</span>
                  </div>
                  <div className="info-location">
                    <LocationMark />
                    <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_76')}</span>
                  </div>
                  <div className="info-location">
                    <LocationMark />
                    <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_77')}</span>
                  </div>
                  <div className="info-location">
                    <LocationMark />
                    <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_78')}</span>
                  </div>
                  <div className="info-location">
                    <LocationMark />
                    <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_79')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btns">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="#FFED0A"
          hoverBackground="#FFED0A"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'usage_attitude_11',
          )}
        />
      </div>
    </ServiceDesign4serviceStyles>
  );
};
