import styled from 'styled-components';

export const ServiceDesignPresentationsStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    color: #000000;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .cases {
    max-width: 870px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      width: 100%;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        gap: 10px;
      }
    }

    &-item {
      box-sizing: border-box;
      padding: 6px 10px;
      background: #bcbcbc;
      border-radius: 100px;
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      line-height: 110%;
      color: #000000;

      @media (max-width: 768px) {
        padding: 4px 8px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .case {
    width: 100%;
    position: relative;

    @media (max-width: 991px) {
      padding-bottom: 70px;
    }

    img {
      width: 100%;
    }

    &-prev {
      background: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #000;
      cursor: pointer;
      transform: translate(0, -50%) rotate(180deg);
      position: absolute;
      top: 50%;
      left: -120px;

      @media (max-width: 1200px) {
        left: -70px;
      }

      @media (max-width: 991px) {
        left: 25px;
        top: inherit;
        bottom: 0;
      }

      &:hover {
        background: #ffed0a;
      }
    }

    &-next {
      background: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #000;
      cursor: pointer;
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: -120px;

      @media (max-width: 1200px) {
        right: -70px;
      }

      @media (max-width: 991px) {
        right: 25px;
        top: inherit;
        bottom: 0;
      }

      &:hover {
        background: #ffed0a;
      }
    }
  }
`;
