import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { ServiceDesignIntroStyles } from './styles';

export const ServiceDesignIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ServiceDesignIntroStyles>
      <Banner
        bgColor="#FFED0A"
        title={getTranslationsByLangOrEng(
          interfaceLanguage,
          'service_design_2',
        )}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'service_design_3',
        )}
        buttonKey="service_design_4"
        btnColor="#000"
        btnHoverColor="#fff"
      />
    </ServiceDesignIntroStyles>
  );
};
