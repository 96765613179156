import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignPresentationsStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import { partners } from './utils';
import ArrowRightButtonIcon from '../../../assets/icons/ArrowRightButtonIcon';

type TCase = {
  selectedCase: { label: string, slides: string[] }
}

const Case: FC<TCase> = ({ selectedCase }) => {
  const [slide, setSlide] = useState<number>(0);

  const nextSlide = () => {
    setSlide((prevSlide) => (prevSlide === selectedCase.slides.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setSlide((prevSlide) => (prevSlide === 0 ? selectedCase.slides.length - 1 : prevSlide - 1));
  };

  useEffect(() => {
    setSlide(0);
  }, [selectedCase]);

  return (
    <div className="case">
      <button
        type="button"
        onClick={prevSlide}
        aria-label="btn"
        className="case-prev"
      >
        <ArrowRightButtonIcon color="#000" />
      </button>
      <img src={selectedCase.slides[slide]} alt="" />
      <button
        type="button"
        onClick={nextSlide}
        aria-label="btn"
        className="case-next"
      >
        <ArrowRightButtonIcon color="#000" />
      </button>
    </div>
  );
};

export const ServiceDesignPresentations = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [selectedCase, setSelectedCase] = useState<{ label: string, slides: string[] }>(partners[0]);

  return (
    <ServiceDesignPresentationsStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_80')}</h1>
        <div className="cases">
          <Case selectedCase={selectedCase} />
          <div className="cases-menu">
            {partners.map((el, index) => (
              <div
                key={el.label}
                className="cases-item"
                onClick={() => setSelectedCase(partners[index])}
              >
                {el.label}
              </div>
            ))}
          </div>
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#000"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="#FFED0A"
          hoverBackground="#FFED0A"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'usage_attitude_11',
          )}
        />
      </div>
    </ServiceDesignPresentationsStyles>
  );
};
