import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignToolsStyles } from './styles';
import CheckMarkServiceDesign from '../../../assets/icons/CheckMarkServiceDesign';

export const ServiceDesignTools = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignToolsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_53')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_54')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_55')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_56')}</p>
          </div>
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_57')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_58')}</p>
          </div>
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_59')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_60')}</p>
          </div>
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_61')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_62')}</p>
          </div>
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_63')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_64')}</p>
          </div>
          <div className="cards-item">
            <div className="cards-title">
              <CheckMarkServiceDesign />
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_65')}</span>
            </div>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_66')}</p>
          </div>
        </div>
      </div>
    </ServiceDesignToolsStyles>
  );
};
