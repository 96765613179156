import React from 'react';

function LongArrow() {
  return (
    <svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM27.5303 6.53033C27.8232 6.23743 27.8232 5.76256 27.5303 5.46967L22.7574 0.696697C22.4645 0.403804 21.9896 0.403804 21.6967 0.696697C21.4038 0.989591 21.4038 1.46446 21.6967 1.75736L25.9393 6L21.6967 10.2426C21.4038 10.5355 21.4038 11.0104 21.6967 11.3033C21.9896 11.5962 22.4645 11.5962 22.7574 11.3033L27.5303 6.53033ZM1 6.75L27 6.75L27 5.25L1 5.25L1 6.75Z" fill="black" />
    </svg>
  );
}

export default LongArrow;
