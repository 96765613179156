import styled from 'styled-components';

export const ServiceDesignWhyStyles = styled.div`
  padding: 70px 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .content {
    gap: 25px !important;
  }

  img {
    width: 100%;
  }

  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
