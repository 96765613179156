import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignWorkshopsStyles } from './styles';
import workshops from '../../../assets/images/serviceDesign/workshops.png';

export const ServiceDesignWorkshops = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignWorkshopsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'solutions')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_37')}
          </h2>
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_38')}
          </p>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={workshops} alt="" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_39')}
            </p>
          </div>
          <div className="cards-item">
            <img src={workshops} alt="" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_40')}
            </p>
          </div>
          <div className="cards-item">
            <img src={workshops} alt="" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_41')}
            </p>
          </div>
        </div>
      </div>
    </ServiceDesignWorkshopsStyles>
  );
};
