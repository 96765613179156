import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignSolutions } from './styles';
import solution1 from '../../../assets/images/serviceDesign/solution-1.png';
import solution2 from '../../../assets/images/serviceDesign/solution-2.png';
import solution3 from '../../../assets/images/serviceDesign/solution-3.png';
import solution4 from '../../../assets/images/serviceDesign/solution-4.png';
import solution5 from '../../../assets/images/serviceDesign/solution-5.png';
import solution6 from '../../../assets/images/serviceDesign/solution-6.png';

export const ServiceDesignSolution = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignSolutions>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'usage_attitude_27')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_9')}
          </h2>
          <div className="cards">
            <div className="cards-item">
              <img src={solution1} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_13')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_14')}</p>
            </div>
            <div className="cards-item">
              <img src={solution2} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_15')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_16')}</p>
            </div>
            <div className="cards-item">
              <img src={solution3} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_17')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_18')}</p>
            </div>
            <div className="cards-item">
              <img src={solution4} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_19')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_20')}</p>
            </div>
            <div className="cards-item">
              <img src={solution5} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_21')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_22')}</p>
            </div>
            <div className="cards-item">
              <img src={solution6} alt="" />
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_23')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_24')}</p>
            </div>
          </div>
        </div>
      </div>
    </ServiceDesignSolutions>
  );
};
