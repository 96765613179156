import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ServiceDesignWhyStyles } from './styles';
import serviceWhy from '../../../assets/images/serviceDesign/service-why.png';

export const ServiceDesignWhy = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ServiceDesignWhyStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_25')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_26')}
          </h2>
          <img src={serviceWhy} alt="" />
          <p>
            {getTranslationsByLangOrEng(interfaceLanguage, 'service_design_27')}
          </p>
        </div>
      </div>
    </ServiceDesignWhyStyles>
  );
};
