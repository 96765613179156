import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import SwiperCenteredSlidesPerViewAuto from '../../swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { SwiperSlide } from 'swiper/react';
import { ServiceDesignVideosliderStyles } from './styles';

export const ServiceDesignVideoslider = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ServiceDesignVideosliderStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'service_design_44')}</h1>
      </div>
      <div className="sliderWrapper">
        <SwiperCenteredSlidesPerViewAuto
          centeredSlides
          loop
          buttonBorderColor="#FFED0A"
          buttonsIconsColor="#000"
        >
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/rVGpvjMM8lg?si=NIf0-MFiAZhHiDNo" title="Service Design Art Conference 2020: How it was" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/YZQu4-pTz9I?si=LMkR1wxjuXiL1OvH" title="Online session with Adela Cristea, service design expert from DesignThinkers Academy: answers to questions from course participants, experience based on real cases" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/qd3yfRR8T9U?si=NP3o4VuKWOETJa53" title="Service Design FAQs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/zbTYxOhwnEc?si=qMV9FOx8BdBFXOM4" title="Alexandra Lee: Service as a competitive advantage" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/5V4bnSJfl10?si=SxWAySiII-Zs03y3" title="Adela Cristea: Service design - a fashion trend or a necessity for companies" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/H87o8YrB3eQ?si=mwQSx0G2yp41aavh" title="Yuen Yen TSAI: License to Play or Why Play is a Portal to Creative Thinking" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/5eCKGRUZo-4?si=_dO4NIWE2x0WpY4a" title="Robert Bloom: Design Thinking is a complex project, not a workshop" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/WldjDL0F4RY?si=62vjHhFXA6SuUP20" title="Vladimir Tsaklev: How to bring your services to a new level with the help of service design" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <iframe width="770" height="370" src="https://www.youtube.com/embed/_MP1bn7G_0Y?si=QeLzOpHChtVG3DWb" title="Adela Cristea: Wow Service Forum 2021" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
          </SwiperSlide>
        </SwiperCenteredSlidesPerViewAuto>
      </div>
    </ServiceDesignVideosliderStyles>
  );
};
